import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { useAuth } from './AuthContext';
import '../styles/HomePage.css';
import '../index.css';

function LoginPage() {
  const [username, setUsername] = useState('');
  const [password, setPassword] = useState('');
  const { login } = useAuth(); 
  const navigate = useNavigate();

  const handleUsernameChange = (event) => {
    setUsername(event.target.value);
  };

  const handlePasswordChange = (event) => {
    setPassword(event.target.value);
  };

  const handleSubmit = (event) => {
    event.preventDefault();
    login(username);
    navigate('/');
  };

  return (
    <div className="login-page">
        <div className="fancybox">
          <h1>Login to Your Account</h1>
          <form onSubmit={handleSubmit}>
            <div className="form-group" style={{ display: 'flex', flexDirection: 'column', alignItems: 'center', gap: '10px' }}>
              <label htmlFor="username" >Username:</label>
              <input type="text" id="username" value={username} onChange={handleUsernameChange} required />
              <label htmlFor="password" >Password:</label>
              <input type="password" id="password" value={password} onChange={handlePasswordChange} required />
            </div>
            <button type="submit" className="login-button" style={{ marginTop: '10px' }}>Login</button>
          </form>
        </div>
      </div>

  );
}

export default LoginPage;