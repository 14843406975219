// client/src/components/Research/ResponsesView.js
import React from 'react';

const ResponsesView = ({ selectedTopic, personas }) => (
  <div className="responses-overview">
    {selectedTopic.questions.map(question => (
      <div key={question.id} className="response-item">
        <h4>{question.text}</h4>
        <div className="persona-responses">
          {personas.map(persona => (
            <div key={persona.id} className="persona-response">
              <strong>{persona.name}:</strong> {persona.responses?.[question.id] || 'No response'}
            </div>
          ))}
        </div>
      </div>
    ))}
  </div>
);

export default ResponsesView;