import { QuestionType } from './ResearchConstants';


export const generateUniqueId = () => {
  return Math.random().toString(36).substr(2, 9);
};

export const validateFilters = (filters) => {
  return {
    ageRange: {
      min: Math.max(18, Math.min(filters.ageRange.min, 100)),
      max: Math.max(18, Math.min(filters.ageRange.max, 100))
    },
    region: filters.region || 'All',
    incomeLevel: filters.incomeLevel || 'All',
    count: Math.max(1, Math.min(filters.count, 10))
  };
};


export const topicActions = {
  addTopic: (name, setTopics, setNewTopicName, setSelectedTopic, setSelectedTab) => {
    const newTopic = {
      id: generateUniqueId(),
      name,
      questions: Array(5).fill(null).map(() => ({
        id: generateUniqueId(),
        text: 'New Question',
        type: Math.random() < 0.5 ? QuestionType.OPEN_ENDED : QuestionType.MULTIPLE_CHOICE,
        options: Math.random() < 0.5 ? Array(Math.floor(Math.random() * 4) + 2).fill(null).map(() => generateUniqueId()) : []
      }))
    };
    setTopics(prev => [...prev, newTopic]);
    setNewTopicName('');
    setSelectedTopic(newTopic);
    setSelectedTab('Questions');
    

  },
  
  newResearch: (setSelectedTopic, setSelectedTab) => {
    setSelectedTopic(null);
    setSelectedTab('Questions');
  },

  deleteTopic: (topicId, topics, setTopics, setSelectedTopic) => {
    setTopics(topics.filter(t => t.id !== topicId));
    setSelectedTopic(null);
  }

};

export const questionActions = {
  addQuestion: (selectedTopic, setSelectedTopic) => {
    const newQuestion = {
      id: generateUniqueId(),
      text: 'New Question',
      type: QuestionType.OPEN_ENDED
    };
    setSelectedTopic({
      ...selectedTopic,
      questions: [...selectedTopic.questions, newQuestion]
    });
  },

  editQuestion: (questionId, updates, selectedTopic, setSelectedTopic) => {
    const updatedQuestions = selectedTopic.questions.map(q =>
      q.id === questionId ? { ...q, ...updates } : q
    );
    setSelectedTopic({
      ...selectedTopic,
      questions: updatedQuestions
    });
  },

  deleteQuestion: (questionId, selectedTopic, setSelectedTopic) => {
    setSelectedTopic({
      ...selectedTopic,
      questions: selectedTopic.questions.filter(q => q.id !== questionId)
    });
  }
};

export const personaActions = {
  addPersona: (personas, setPersonas) => {
    const newPersona = {
      id: generateUniqueId(),
      name: 'New Persona',
      age: '',
      location: '',
      bio: ''
    };
    setPersonas([...personas, newPersona]);
  },

  editPersona: (personaId, updates, personas, setPersonas) => {
    setPersonas(personas.map(p =>
      p.id === personaId ? { ...p, ...updates } : p
    ));
  },

  deletePersona: (personaId, personas, setPersonas) => {
    setPersonas(personas.filter(p => p.id !== personaId));
  },

  generatePersonas: async (filters, setPersonas) => {
    // API call or generation logic here
    // For now, just create dummy personas
    const dummyPersonas = Array(filters.count).fill(null).map(() => ({
      id: generateUniqueId(),
      name: 'Generated Persona',
      age: Math.floor(Math.random() * (filters.ageRange.max - filters.ageRange.min) + filters.ageRange.min),
      location: filters.region,
      bio: 'Generated bio'
    }));
    setPersonas(dummyPersonas);
  },

  showMorePersonas: (currentVisible, setVisibleCount) => {
  setVisibleCount(Math.min(currentVisible + 2, 10));
  }
};

