export const QuestionType = {
    OPEN_ENDED: 'OPEN_ENDED',
    MULTIPLE_CHOICE: 'MULTIPLE_CHOICE',
    TRUE_FALSE: 'TRUE_FALSE'
  };
  
  export const defaultPersonaFilters = {
    ageRange: { min: 18, max: 65 },
    region: 'All',
    incomeLevel: 'All',
    count: 5
  };
  
  export const MAX_VISIBLE_PERSONAS = 8;