import React from 'react';
import { BrowserRouter as Router, Routes, Route, useLocation } from 'react-router-dom';
import Header from './components/Header';
import Footer from './components/Footer';
import HomePage from './pages/HomePage';
import BlogPage from './pages/BlogPage';
import ResearchPage from './research/ResearchPage';
import AboutPage from './pages/AboutPage';
import LoginPage from './pages/LoginPage';
import Pricing from './pages/Pricing';
import OurPersonas from './pages/OurPersonas';

function App() {
  return (
    <Router>
      <div className="app">
        <Header />
        <main>
          <Routes>
            <Route path="/" element={<HomePage />} />
            <Route path="/ourpersonas" element={<OurPersonas />} />
            <Route path="/blogs" element={<BlogPage />} />
            <Route path="/research" element={<ResearchPage />} />
            <Route path="/about" element={<AboutPage />} />
            <Route path="/login" element={<LoginPage />} />
            <Route path="/pricing" element={<Pricing />} />
          </Routes>
        </main>
        <ConditionalFooter />
      </div>
    </Router>
  );
}

function ConditionalFooter() {
  const location = useLocation();
  
  if (location.pathname === '/research') {
    return null;
  }
  
  return <Footer />;
}

export default App;