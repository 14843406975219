// client/src/components/Research/QuestionView.js
import React, { useState } from 'react';
import { QuestionType } from './ResearchConstants';
import editPencil from '../components/assets/icons/edit_pencil.png';
import trashBin from '../components/assets/icons/trash_bin.png';
import '../styles/ResearchPage.css';


const Question = ({ question, onEdit, onDelete }) => {
  const [isEditing, setIsEditing] = useState(false);
  const [questionText, setQuestionText] = useState(question.text);
  const [options, setOptions] = useState(question.options || []);

  const handleSave = () => {
    onEdit(question.id, { text: questionText, options });
    setIsEditing(false);
  };

  const handleKeyDown = (e) => {
    if (e.key === 'Enter') {
      handleSave();
    }
  };

  return (
    <div className="question-item">
        <div className="question-item" style={{ minWidth: '90%' }}>
          {isEditing ? (
            <>
              <input
                value={questionText}
                onChange={(e) => setQuestionText(e.target.value)}
                onKeyDown={handleKeyDown}
              />
              <select 
                value={question.type} 
                onChange={(e) => onEdit(question.id, { type: e.target.value })}
              >
                <option value={QuestionType.OPEN_ENDED}>Open-Ended</option>
                <option value={QuestionType.MULTIPLE_CHOICE}>Multiple Choice</option>
                <option value={QuestionType.TRUE_FALSE}>True/False</option>
              </select>
              {(question.type === QuestionType.MULTIPLE_CHOICE || question.type === QuestionType.TRUE_FALSE) && (
                <div className="options">
                  {options.map((option, index) => (
                    <input
                      key={index}
                      value={option}
                      onChange={(e) => {
                        const newOptions = [...options];
                        newOptions[index] = e.target.value;
                        setOptions(newOptions);
                      }}
                      onKeyDown={handleKeyDown}
                    />
                  ))}
                  <button onClick={() => setOptions([...options, ''])}>Add Option</button>
                </div>
              )}
              <button onClick={handleSave}>Save</button>
            </>
          ) : (
            <>
              <>{question.text}</>
              <div></div>
              <>{question.type}</>
              {options.length > 0 && (
                <div className="options">
                  {options.map((option, index) => (
                    <li key={index}>{option}</li>
                  ))}
                </div>
              )}
            </>
          )}
      </div>
      <div className="tabs" style={{justifyContent: 'center'}}>
        <div className="tab" onClick={() => setIsEditing(true)} style={{ maxWidth: '1.5rem' }}>
          <img src={editPencil} alt="Edit" onClick={() => setIsEditing(true)} className="click-icon" />
        </div>
        <div className="tab" onClick={() => onDelete(question.id)} style={{ maxWidth: '1.5rem' }}>
          <img src={trashBin} alt="Delete" onClick={() => onDelete(question.id)} className="click-icon" />
        </div>
      </div>
    </div>
  );
};

export default Question;