import '../styles/ResearchPage.css';
import React, { useState } from 'react';
import TabContent from './TabContent';
import { 
  topicActions, questionActions, personaActions} from './ResearchActions';
import trashBin from '../components/assets/icons/trash_bin.png';
import { MAX_VISIBLE_PERSONAS } from './ResearchConstants';

function ResearchPage() {
  const [topics, setTopics] = useState([]);
  const [selectedTopic, setSelectedTopic] = useState(null);
  const [selectedTab, setSelectedTab] = useState('Questions');
  const [newTopicName, setNewTopicName] = useState('');
  const [personas, setPersonas] = useState([]);
  const [visibleCount, setVisibleCount] = useState(MAX_VISIBLE_PERSONAS);
  const [isSidebarVisible, setIsSidebarVisible] = useState(true);

  const handleNewResearchClick = () => {
    topicActions.newResearch(setSelectedTopic, setSelectedTab);
  };
  
  const handleTopicClick = (topic) => {
    setSelectedTopic(topic);
    setSelectedTab('Questions');
    setPersonas([]);
    setVisibleCount(MAX_VISIBLE_PERSONAS);
  };

  const handleNextTab = () => {
    if (selectedTab === 'Questions') setSelectedTab('Personas');
    else if (selectedTab === 'Personas') setSelectedTab('Responses');
  };

  const handleAddTopic = () => {
    topicActions.addTopic(newTopicName, setTopics, setNewTopicName, setSelectedTopic, setSelectedTab);
  };

  const handleKeyDown = (e) => {
    if (e.key === 'Enter' && newTopicName.trim()) {
      handleAddTopic();
    }
  };

  const TopicList = ({ topics, onTopicClick, onTopicDelete }) => (
    <div className="topic-list">
      <ul>
        {topics.map((topic) => (
          <li key={topic.id} onClick={() => onTopicClick(topic)}>
            <span>{topic.name}</span>
            <img 
              src={trashBin} 
              alt="Delete" 
              onClick={(e) => {
                e.stopPropagation();
                onTopicDelete(topic.id);
              }} 
              className="click-icon" 
            />
          </li>
        ))}
      </ul>
    </div>
  );

  return (
    <div className="research-page">
      <aside className={`sidebar ${isSidebarVisible ? 'visible' : 'collapsed'}`}>
        <div className="sidebar-header">
          <h2>Your Research Topics</h2>
          <button 
            className="toggle-sidebar"
            onClick={() => setIsSidebarVisible(!isSidebarVisible)}
          >
            {isSidebarVisible ? '←' : '→'}
          </button>
        </div>
        <button className="research-button" onClick={handleNewResearchClick}>
          Add Research Topic
        </button>
        <TopicList
          topics={topics}
          selectedTopic={selectedTopic}
          newTopicName={newTopicName}
          setNewTopicName={setNewTopicName}
          onTopicClick={handleTopicClick}
          onAddTopic={handleAddTopic}
          onTopicDelete={(topicId) => topicActions.deleteTopic(topicId, topics, setTopics, setSelectedTopic)}
        />
      </aside>

      <main className="research-content">
      {selectedTopic ? (
        <>
        <h2>{selectedTopic.name}</h2>
        <div className="tabs">
          {['Questions', 'Personas', 'Responses'].map(tab => (
          <button
          key={tab}
          className={`tab ${selectedTab === tab ? 'active' : ''}`}
          onClick={() => setSelectedTab(tab)}
          >
            {tab}
          </button>
          ))}
        </div>
        <div className="tab-content">
          <TabContent
          selectedTab={selectedTab}
          selectedTopic={selectedTopic}
          onQuestionEdit={(questionId, updates) => questionActions.editQuestion(questionId, updates, selectedTopic, setSelectedTopic)}
          onQuestionDelete={(questionId) => questionActions.deleteQuestion(questionId, selectedTopic, setSelectedTopic)}
          onAddQuestion={() => questionActions.addQuestion(selectedTopic, setSelectedTopic)}
          personas={personas}
          setPersonas={setPersonas}
          onPersonaEdit={(personaId, updates) => personaActions.editPersona(personaId, updates, personas, setPersonas)}
          onPersonaDelete={(personaId) => personaActions.deletePersona(personaId, personas, setPersonas)}
          onAddPersona={() => personaActions.addPersona(personas, setPersonas)}
          onNextTab={handleNextTab}
          visibleCount={visibleCount}
          setVisibleCount={setVisibleCount}
          />
        </div>
        </>
        ) : (
          <div className="new-topic">
          <h3>What would you like to research?</h3>
          <input
          value={newTopicName}
          onChange={(e) => setNewTopicName(e.target.value)}
          placeholder="Enter new topic description"
          onKeyDown={handleKeyDown}
          />
          <button
          className="research-button" 
          onClick={handleAddTopic}
          disabled={!newTopicName.trim()}
          >
          Submit
          </button>
          </div>
        )}
      </main>
      </div>
  );
}

export default ResearchPage;
