// client/src/components/Research/TabContent.js
import '../styles/ResearchPage.css';
import React from 'react';
import Question from './QuestionView';
import {Persona, PersonaFilters} from './PersonaView';
import ResponsesView from './ResponsesView';
import { personaActions } from './ResearchActions';
import { MAX_VISIBLE_PERSONAS, QuestionType } from './ResearchConstants';


const TabContent = ({ 
  selectedTab, 
  selectedTopic, 
  onQuestionEdit, 
  onQuestionDelete, 
  onAddQuestion,
  personas,
  setPersonas,
  onPersonaEdit,
  onPersonaDelete,
  onAddPersona,
  onNextTab,
  visibleCount,
  setVisibleCount   
}) => {
  switch (selectedTab) {
    case 'Questions':
      return (
        <div className="questions-container">
          <h3>Questions Overview</h3>
          {selectedTopic.questions.map((question) => (
            <Question 
              key={question.id} 
              question={question} 
              onEdit={onQuestionEdit} 
              onDelete={onQuestionDelete} 
            />
          ))}
          <button className="research-button" onClick={onAddQuestion}>Add Question</button>
          <button className="research-button" onClick={onNextTab}>Next Step</button>
        </div>
      );
    case 'Personas':
      return (
        <div className="researchPersonas-container">
        <h3>Persona Overview</h3>
        {personas.length === 0 ? (
          <PersonaFilters onApplyFilters={(filters) => personaActions.generatePersonas(filters, setPersonas)} />
        ) : (
          <div className="researchPersonas-container">
              <h3>You have {personas.length} Persona{personas.length > 1 ? 's' : ''}</h3>
          <div className="researchPersonas-grid">
            {personas.slice(0, visibleCount).map((persona) => (
              <Persona
                key={persona.id}
                persona={persona}
                onEdit={onPersonaEdit}
                onDelete={onPersonaDelete}
              />
            ))}
          </div>
          <></>
          {personas.length > 8 && visibleCount < personas.length && (
            <button 
              className="research-button"
              onClick={() => personaActions.showMorePersonas(visibleCount, setVisibleCount)}
            >
              Show More
            </button>
          )}
          <button className="research-button" onClick={onAddPersona}>
            Add Persona
          </button>
          <button className="research-button" onClick={onNextTab}>
            Next Step
          </button>
        </div>
        )}
      </div>
      );
    case 'Responses':
      return (
        <div className="responses-container">
          <h3>Responses Overview</h3>
          <ResponsesView 
            selectedTopic={selectedTopic}
            personas={personas}
          />
          <button className="research-button" onClick={() => alert('Please upgrade your plan for premium features')}>
            Analyse Responses with AI
          </button>
        </div>
      );
    default:
      return null;
  }
};

export default TabContent;