import React from 'react';
import { useAuth } from '../pages/AuthContext';
import '../styles/Footer.css';

function Footer() {
  const { user } = useAuth();

  return (
    <footer className="footer">
        {user && <p style={{margin: '5px'}}>Welcome, {user}!</p>}
        <nav>
            <a href="/privacy-policy">Privacy Policy</a>
        </nav>
        <nav>
          <a href="/terms-of-service">Terms of Service</a>
        </nav>
        <p style={{margin: '10px'}}>&copy; 2024 InstantFlows. All rights reserved.</p>
    </footer>
  );
}

export default Footer;