import React, { useState, useEffect } from 'react'; // Added useState and useEffect
import BlogList from '../components/BlogList';
import '../styles/BlogPage.css';

function BlogPage() {
  const [blogs, setBlogs] = useState([]); // Added state for blogs
  const [loading, setLoading] = useState(true); // Added loading state
  const [error, setError] = useState(null); // Added error state

  useEffect(() => {
    const fetchBlogs = async () => {
      try {
        const response = await fetch('/api/blogs');
        if (!response.ok) throw new Error('Network response was not ok');
        const data = await response.json();
        setBlogs(data);
      } catch (error) {
        setError(error.message);
      } finally {
        setLoading(false);
      }
    };

    fetchBlogs();
  }, []);

  if (loading) return <div>Loading...</div>; // Handle loading state
  if (error) return <div>Error: {error}</div>; // Handle error state

  return (
    <div className="blog-page">
      <div className="hero" style={{ display: 'grid' }}>
        <h1>Blogs</h1>
        <p>Explore the latest insights and trends in the industry.</p>
      </div>
      <div className="blog-view">
        {blogs.map((blog, index) => ( // Extracting and displaying blog content directly
          <div key={index} className="blog-box">
            <h2>{blog.title}</h2> {/* Display title */}
            <p>{blog.content}</p> {/* Display content */}
            <p className="blog-date">{new Date(blog.date).toLocaleDateString()}</p>
          </div>
        ))}
      </div>
    </div>
  );
}

export default BlogPage;