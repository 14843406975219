import React from 'react';
import BlogList from '../components/BlogList';
import '../styles/HomePage.css';
import '../index.css';
import { Swiper, SwiperSlide } from 'swiper/react';
import 'swiper/css';
import 'swiper/css/navigation';
import 'swiper/css/pagination';
import { Navigation, Pagination } from 'swiper';



function HomePage() {
  return (
    <div className="home-page">
      <div className="hero-1">
        <div className="hero-grid-item">
          <h1>Instantly understand your market with AI Surveys</h1>
        </div>
        <div className="hero-grid-item">
          <h2>"Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat."</h2>          
        </div>
        <button className="button">Start Research</button>
      </div>
      
      
      <h2>Meet our personas</h2>

      <div className="body">
        
        <div className="slide-container">
          <Swiper
            modules={[Pagination, Navigation]}
            
            initialSlide={2}
            spaceBetween={30}
            slidesPerGroup={1}
            loop={true}
            pagination={{
              clickable: true,
            }}
            navigation={true}
            className="mySwiper"
            
            breakpoints={{
              520: {slidesPerView: 1},
              600: {slidesPerView: 2},
              1100: {slidesPerView: 3},
              1600: {slidesPerView: 4}

            }}
          >
            <SwiperSlide>
              <div className ="slide-content">
                <div className="card-wrapper swiper-wrapper">
                  <div className="card swiper-slide">
                    <div className="image-content">
                      <span className='overlay'></span>

                      <div className="card-image"> 
                        <img src= '/InstantFlowsicon_wb.png' alt="" className="card-img" ></img>
                      </div>
                    </div>
                    <div className="card-content">
                      <h2 className="name">Leyla</h2>
                      <p className="description">Medical assistant 29 years old€ 38 584 per year Unmarried | No children</p>
                      <button className="button">View more</button>
                    </div>
                  </div>
                </div>
              </div>
            </SwiperSlide>

            {/*  Slide 2  */} 
            <SwiperSlide>
              <div className="slide-content">
                <div className="card-wrapper swiper-wrapper">
                  <div className="card swiper-slide">
                    <div className="image-content">
                      <span className='overlay'></span>

                      <div className="card-image"> 
                        <img src= '/InstantFlowsicon_wb.png' alt="" className="card-img" ></img>
                      </div>
                    </div>
                    <div className="card-content">
                      <h2 className="name">Mark</h2>
                      <p className="description">Medical assistant 29 years old€ 38 584 per year Unmarried | No children</p>
                      <button className="button">View more</button>
                    </div>
                  </div>
                </div>
              </div>
            </SwiperSlide>  

            {/*  Slide 3  */} 
            <SwiperSlide>
              <div className="slide-content">
                <div className="card-wrapper swiper-wrapper">
                  <div className="card swiper-slide">
                    <div className="image-content">
                      <span className='overlay'></span>

                      <div className="card-image"> 
                        <img src= '/InstantFlowsicon_wb.png' alt="" className="card-img" ></img>
                      </div>
                    </div>
                    <div className="card-content">
                      <h2 className="name">Leyla</h2>
                      <p className="description">Medical assistant 29 years old€ 38 584 per year Unmarried | No children</p>
                      <button className="button">View more</button>
                    </div>
                  </div>
                </div>
              </div>
            </SwiperSlide>  

          </Swiper>
        </div>
      </div>
      
      <h2>How It Works</h2>
      <div className="how-it-works">
        
        <h3>1. Define Your Target Audience:</h3> <p>Tell us about your ideal customer using our flexible demographic input system.</p>
        <h3>2. Generate AI-Powered Personas:</h3> <p>Our AI instantly creates detailed, realistic personas representing your target market.</p>
        <h3>3. Build Your Survey:</h3> <p>Input your research topic, and our AI will generate a tailored survey with relevant questions. You can easily customize these questions or add your own.</p>
        <h3>4. Get Instant Results:</h3> <p>Our AI simulates thousands of responses from your personas, providing real-time statistical analysis and actionable insights.</p>
        
      </div>

      <div className="hero-2" >
          <h1>Get up to ??% accuracy,   for only 1/?th of the price</h1>
          <h2>Within only ? seconds </h2>
         
       </div>
      
      {/*

      <div id="content" className="container">
        <div className="blog-section">
          <h2 className="section-title">Latest Blogs</h2>
          <BlogList/>
        </div>
        
        <section className="research-examples-section">
          <h2 className="section-title">Research Examples</h2>
          <div className="swiper-container">
            <Swiper
              spaceBetween={50}
              slidesPerView={1}
              autoplay={{ delay: 5000 }}
              navigation
              pagination={{ clickable: true }}
              modules={[Navigation, Pagination]}
            >
              <SwiperSlide>
                <div className="carousel-item" style={{ maxWidth: '90%', margin: 'auto', marginBottom: '50px' }}>
                  <h3>Marketing Manager Persona</h3>
                  <p>By generating a persona for a Marketing Manager, we discovered that they prioritize campaign tracking and analysis. This insight led to the development of a more comprehensive analytics dashboard, resulting in a 25% increase in campaign ROI.</p>
                </div>
              </SwiperSlide>
              <SwiperSlide>
                <div className="carousel-item" style={{ maxWidth: '90%', margin: 'auto', marginBottom: '50px' }}>
                  <h3>Field Research on Competitor Analysis</h3>
                  <p>Conducting field research on competitor analysis revealed that our target audience values real-time market insights. This led to the integration of AI-powered competitor tracking, enabling our users to make data-driven decisions and stay ahead of the competition.</p>
                </div>
              </SwiperSlide>
              <SwiperSlide>
                <div className="carousel-item" style={{ maxWidth: '90%', margin: 'auto', marginBottom: '50px' }}>
                  <h3>Insights from Market Idea Testing</h3>
                  <p>Through market idea testing, we gained insights into the most effective marketing strategies for our target audience. This knowledge was used to develop targeted campaigns, resulting in a 30% increase in lead generation.</p>
                </div>
              </SwiperSlide>
            </Swiper>
          </div>

          <div className="get-started-btn-container" style={{textAlign: 'center'}}>
            <a href="/research" className="btn">Get Started</a>
          </div>
        </section>

        <div className="fancybox" style={{ maxWidth: '100%'}}>
          <p>We're working hard to bring you this amazing service. Sign up to stay updated!</p>

          <form action="https://formspree.io/f/mwpkkogl" method="POST">
              <input type="text" id="name" name="name" placeholder="Your Name" required />
              <input type="email" id="email" name="_replyto" placeholder="Your Email" required />
              <btn type="submit">Keep Me Updated</btn>
          </form>

          <p><small>By submitting this form, you agree to our privacy policy.</small></p>
        </div>
      </div>
      */}
    </div>
  );
}



export default HomePage;


