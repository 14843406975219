import React from 'react';
import '../index.css';
import '../styles/Pricing.css';
import InstantFlowsLogoSub from '../components/assets/InstantFlowsLogo - subtitle.png';

function Pricing() {
  return (
    <div className="pricing-page">
      <section className="hero">
        <h1>Pricing</h1>
        <p>Choose your plan</p>
      </section>

      <div className="pricing-container">
        <div className="pricing-card">
          <h2>Free</h2>
          <div className="price">
            <span className="amount">€0</span>
            <span className="period">/month</span>
          </div>
          <ul>
            <li>1 Basic Survey per week</li>
            <li>No Survey History</li>
          </ul>
          <button className="pricing-btn">Get Started</button>
        </div>

        <div className="pricing-card featured">
          <h2>Basic</h2>
          <div className="price">
            <span className="amount">€5</span>
            <span className="period">/month</span>
          </div>
          <ul>
            <li>1 Basic Survey per day</li>
            <li>1 Premium Survey per Week</li>
            <li>History of all previous surveys</li>
          </ul>
          <button className="pricing-btn">Get Started</button>
        </div>

        <div className="pricing-card">
          <h2>Premium</h2>
          <div className="price">
            <span className="amount">€29</span>
            <span className="period">/month</span>
          </div>
          <ul>
            <li>1 Premium Survery per pay</li>
            <li>History of all previous surveys</li>
            <li>Premium pdf reports</li>
            
          </ul>
          <button className="pricing-btn">Get Started</button>
        </div>
      </div>

      <section className="pricing-faq">
        <h2>Frequently Asked Questions</h2>
        <div className="faq-container">
          <div className="faq-item">
            <h3>Can I change plans later?</h3>
            <p>Yes, you can upgrade or downgrade your plan at any time. Changes will be reflected in your next billing cycle.</p>
          </div>
          <div className="faq-item">
            <h3>Is there a free trial?</h3>
            <p>We offer a 14-day free trial on all plans.</p>
          </div>
        </div>
      </section>
    </div>
  );
}

export default Pricing;
