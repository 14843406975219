import React from 'react';
import '../index.css';
import '../styles/AboutPage.css';
import InstantFlowsLogoSub from '../components/assets/InstantFlowsLogo - subtitle.png';

function AboutPage() {
  return (
    <div className="about-page">
      <div className="hero">
        <h1>About Us</h1>
        {/* <p>InstantFlows is an innovative platform designed to revolutionize market research by providing instant, AI-powered insights.</p> */}
      </div>
      <div className="intro">
        <p>Traditional methods of market research are often time-consuming and expensive, especially for small and medium-sized businesses (SMBs). We understand the challenges businesses face in obtaining timely and cost-effective market data.</p>
        <p>InstantFlows uses powerful AI to make the process faster, more affordable, and accessible to all. Whether you're a seasoned market researcher or just starting, with our platform you can make data-driven decisions with confidence.</p>
      </div>
      
      <h2>How It Works</h2>
      <div className="how-it-works" style={{ width: '80%' }}>
        <div className="question-item">
          <h3>1. Define Your Target Audience:</h3> 
          <p>Tell us about your ideal customer using our flexible demographic input system.</p>
        </div>
        <div className="question-item">
          <h3>2. Generate AI-Powered Personas:</h3> 
          <p>Our AI instantly creates detailed, realistic personas representing your target market.</p>
        </div>
        <div className="question-item">
          <h3>3. Build Your Survey:</h3> 
          <p>Input your research topic, and our AI will generate a tailored survey with relevant questions. You can easily customize these questions or add your own.</p>
        </div>
        <div className="question-item">
          <h3>4. Get Instant Results:</h3> 
          <p>Our AI simulates thousands of responses from your personas, providing real-time statistical analysis and actionable insights.</p>
        </div>
      </div>
      

    </div>



    
    
  );
}

{/*
    <div className="about-page">
      <div className="hero">
        <div className="hero-text">
          <h1>About Us</h1>
          <p>InstantFlows is an innovative platform designed to revolutionize market research by providing instant, AI-powered insights.</p>
        </div>
        <div className="hero-image">
          <img src={InstantFlowsLogoSub} alt="InstantFlows Logo Subtitle" style={{ width: '100%', height: '100%'}} />
        </div>
      </div>

      <div className="content-grid">
        <div className="section-title">
          <h2>Introduction</h2>
        </div>
        <div className="section-content">
          <p>We understand the challenges businesses face in obtaining timely and cost-effective market data. Traditional methods are often time-consuming and expensive, especially for small and medium-sized businesses (SMBs).</p>
          <p>InstantFlows harnesses the power of cutting-edge AI to make the process faster, more affordable, and accessible to all. Whether you're a seasoned market researcher or just starting, our platform empowers you to make data-driven decisions with confidence.</p>
        </div>
        <div className="section-title">
          <h2>Our Mission</h2>
        </div>
        <div className="section-content">
          <ul>
            <li>Delivers high-quality, actionable insights in minutes, not weeks.</li>
            <li>Offers a cost-effective alternative to traditional research methods.</li>
            <li>Empowers businesses of all sizes to make informed decisions based on real-time data.</li>
          </ul>
        </div>
        <div className="section-title">
          <h2>How InstantFlows Works</h2>
        </div>
        <div className="section-content">
          <ol>
            <li>Define Your Target Audience: Tell us about your ideal customer using our flexible demographic input system.</li>
            <li>Generate AI-Powered Personas: Our AI instantly creates detailed, realistic personas representing your target market.</li>
            <li>Build Your Survey: Input your research topic, and our AI will generate a tailored survey with relevant questions. You can easily customize these questions or add your own.</li>
            <li>Get Instant Results: Our AI simulates thousands of responses from your personas, providing real-time statistical analysis and actionable insights.</li>
          </ol>
        </div>
        <div className="section-title">
          <h2>Key Features</h2>
        </div>
        <div className="section-content">
          <ul>
            <li>AI-Generated Personas: Create rich, diverse personas based on detailed demographic information, ensuring your research reflects your target audience.</li>
            <li>Automated Survey Generation: Generate relevant and unbiased survey questions within seconds, saving you time and effort.</li>
            <li>Simulated Responses: Obtain immediate feedback from AI-simulated respondents, providing instant insights without the wait.</li>
            <li>Real-time Analysis and Reporting: Access instant statistical analysis and visually engaging reports to quickly understand key trends and patterns.</li>
            <li>Contextual Awareness: Incorporate recent events and news into responses for questions requiring up-to-date context.</li>
          </ul>
        </div>
        <div className="section-title">
          <h2>Our Technology</h2>
        </div>
        <div className="section-content">
          <ul>
            <li>Advanced AI & Machine Learning: We leverage cutting-edge large language models (LLMs) to power our persona generation, survey creation, and response simulation. Our rigorous selection process ensures we use the best LLM for the job, prioritizing performance, scalability, cost-effectiveness, and seamless API integration.</li>
            <li>Secure and Scalable Infrastructure: Our cloud-based infrastructure ensures your data is safe, secure, and accessible anytime. We use a hybrid database approach combining the strengths of relational and NoSQL databases for optimal performance and data integrity.</li>
          </ul>
        </div>
        <div className="section-title">
          <h2>Why Choose InstantFlows?</h2>
        </div>
        <div className="section-content">
          <ul>
            <li>Speed: Get answers to your research questions within minutes, not weeks or months.</li>
            <li>Affordability: Access high-quality market research at a fraction of the cost of traditional methods, making it ideal for businesses with limited budgets.</li>
            <li>Scalability: Easily scale your research to accommodate larger sample sizes or more complex projects as your needs grow.</li>
            <li>Objectivity: Eliminate human bias from your research with AI-powered insights based on data, not opinions.</li>
            <li>Accessibility: Conduct market research anytime, anywhere, with our easy-to-use platform.</li>
          </ul>
        </div>
        <div className="section-title">
          <h2>Join the InstantFlows Revolution</h2>
        </div>
        <div className="section-content">
          <p>We believe everyone should have access to fast, affordable, and insightful market research. InstantFlows empowers you to make data-driven decisions with confidence, helping your business thrive in today's competitive landscape.</p>
        </div>
      </div>
    </div>
    */}

export default AboutPage;
