import React, { useState } from 'react';
import { useAuth } from '../pages/AuthContext';
import { useNavigate, Link } from 'react-router-dom';
import '../styles/Header.css';
import InstantFlowsLogo from './assets/InstantFlowsLogo-1.png';

function Header() {
  const { user, logout } = useAuth();
  const navigate = useNavigate();
  const [isMenuOpen, setIsMenuOpen] = useState(false);

  const handleLogout = () => {
    logout();
    navigate('/'); // Redirect to home on logout
  };

  return (
    <header className="header">
      <div className="container header-content">
        <Link to="/" className="logo-link">
          <img src={InstantFlowsLogo} alt="InstantFlows Logo" className="logo" />
        </Link>
        <button 
          className="menu-toggle" 
          onClick={() => setIsMenuOpen(!isMenuOpen)} 
          aria-expanded={isMenuOpen} 
          aria-label={isMenuOpen ? 'Close Menu' : 'Open Menu'}
        >
          {isMenuOpen ? 'Menu' : 'Menu'}
        </button>
        <nav className={`overlay-nav ${isMenuOpen ? 'open' : ''}`}>
          <ul className={`nav-links ${isMenuOpen ? 'open' : ''}`}>
          <li><Link to="/about">About us</Link></li>
            <li><Link to="/ourpersonas">Personas</Link></li>
            <li><Link to="/pricing">Pricing</Link></li>
            <li><Link to="/blogs">Blogs</Link></li>         
              {user ? (
              <li><Link to="/login" className="auth-button login-button" onClick={handleLogout}>Logout</Link>
                <Link to="/research" className="auth-button signup-button">Start Research</Link>
              
              </li>
            ) : (
              <li className="auth-buttons-container">
                <Link to="/login" className="auth-button login-button">Login</Link>
                <Link to="/login" className="auth-button signup-button" onClick={() => { alert('You need to be logged in first.')}}>Start Research</Link>
              </li>
            )}
          </ul>
        </nav>
      </div>
    </header>
  );
}

export default Header;