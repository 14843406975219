// client/src/components/Research/PersonaView.js
import React, { useState } from 'react';
import editPencil from '../components/assets/icons/edit_pencil.png';
import trashBin from '../components/assets/icons/trash_bin.png';
import { defaultPersonaFilters } from './ResearchConstants';
import { validateFilters } from './ResearchActions';
import '../styles/ResearchPage.css';



export const PersonaFilters = ({ onApplyFilters }) => {
  const [filters, setFilters] = useState(defaultPersonaFilters);

  const handleSubmit = (e) => {
    e.preventDefault();
    onApplyFilters(validateFilters(filters));
  };

  return (
    <div className="researchPersona-filters">
      <h4>Define Your Target Demographic</h4>
      <form onSubmit={handleSubmit}>
        <div className="filter-group">
          <label>Age Range:</label>
          <div className="age-range">
            <input
              type="number"
              value={filters.ageRange.min}
              onChange={(e) => setFilters({
                ...filters,
                ageRange: { ...filters.ageRange, min: parseInt(e.target.value) }
              })}
              min="18"
              max="100"
            />
            <span>to</span>
            <input
              type="number"
              value={filters.ageRange.max}
              onChange={(e) => setFilters({
                ...filters,
                ageRange: { ...filters.ageRange, max: parseInt(e.target.value) }
              })}
              min="18"
              max="100"
            />
          </div>
        </div>

        <div className="filter-group">
          <label>Region:</label>
          <select
            value={filters.region}
            onChange={(e) => setFilters({ ...filters, region: e.target.value })}
          >
            <option value="All">All Regions</option>
            <option value="North America">North America</option>
            <option value="Europe">Europe</option>
            <option value="Asia">Asia</option>
            <option value="Other">Other</option>
          </select>
        </div>

        <div className="filter-group">
          <label>Income Level:</label>
          <select
            value={filters.incomeLevel}
            onChange={(e) => setFilters({ ...filters, incomeLevel: e.target.value })}
          >
            <option value="All">All Levels</option>
            <option value="Low">Low</option>
            <option value="Medium">Medium</option>
            <option value="High">High</option>
          </select>
        </div>

        <div className="filter-group">
          <label>Number of Personas:</label>
          <input
            type="number"
            value={filters.count}
            onChange={(e) => setFilters({ ...filters, count: parseInt(e.target.value) })}
            min="1"
            max="10"
          />
        </div>

        <button type="submit" className="research-button">
          Populate Personas List
        </button>
      </form>
    </div>
  );
};

export const Persona = ({ persona, onEdit, onDelete }) => {
  const [isEditing, setIsEditing] = useState(false);
  const [personaData, setPersonaData] = useState(persona);

  const handleSave = () => {
    onEdit(persona.id, personaData);
    setIsEditing(false);
  };

  const handleKeyDown = (e) => {
    if (e.key === 'Enter') {
      handleSave();
    }
  };

  return (
    <div className="researchPersona-item">
      {isEditing ? (
        <div className="researchPersona-edit-form">
          <input
            value={personaData.name}
            onChange={(e) => setPersonaData({ ...personaData, name: e.target.value })}
            placeholder="Name"
            onKeyDown={handleKeyDown}
          />
          <input
            value={personaData.age}
            onChange={(e) => setPersonaData({ ...personaData, age: e.target.value })}
            placeholder="Age"
            onKeyDown={handleKeyDown}
          />
          <input
            value={personaData.location}
            onChange={(e) => setPersonaData({ ...personaData, location: e.target.value })}
            placeholder="Location"
            onKeyDown={handleKeyDown}
          />
          <textarea
            value={personaData.bio}
            onChange={(e) => setPersonaData({ ...personaData, bio: e.target.value })}
            placeholder="Bio"
            onKeyDown={handleKeyDown}
          />
          <button onClick={handleSave}>Save</button>
        </div>
      ) : (
        <div className="researchPersona-display">
          <div className="researchPersona-header">
            <h4>{persona.name}</h4>
            <div className="profile-pic">
              <img src= '/InstantFlowsicon_wb.png' alt="Profile" />
            </div>
          </div>
          <div className="researchPersona-details">
            <p>Age: {persona.age}</p>
            <p>Location: {persona.location}</p>
            <p>Bio: {persona.bio}</p>
          </div>
        </div>
      )}
        <div style={{ display: 'flex', justifyContent: 'center' }}>
            <div className="tab" onClick={() => setIsEditing(true)} style={{ maxWidth: '1.5rem', maxHeight: '1.5rem', margin: '0.5rem' }}>
              <img src={editPencil} alt="Edit" onClick={() => setIsEditing(true)} className="click-icon" />
            </div>
            <div className="tab" onClick={() => onDelete(persona.id)} style={{ maxWidth: '1.5rem', maxHeight: '1.5rem', margin: '0.5rem' }}> 
              <img src={trashBin} alt="Delete" onClick={() => onDelete(persona.id)} className="click-icon" />
            </div>
          </div>
    </div>
  );
};

export default Persona;