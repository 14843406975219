import React from 'react';
import '../index.css';
import '../styles/OurPersonas.css';
import { Swiper, SwiperSlide } from 'swiper/react';
import { Grid, Pagination } from 'swiper';
import 'swiper/css';
import 'swiper/css/grid';
import 'swiper/css/pagination';

function OurPersonas() {
  return (
    <div className="personas-page">
      <div className="personas-grid">
        <Swiper
          modules={[Grid, Pagination]}
          grid={{
            rows: 2,
            fill: 'row'
          }}
          spaceBetween={30}
          slidesPerView={4}
          pagination={{
            clickable: true
          }}
          breakpoints={{
            // when window width is >= 320px
            320: {
              slidesPerView: 1,
              grid: {
                rows: 2,
                fill: 'row'
              },
              spaceBetween: 30
            },
            // when window width is >= 480px
            480: {
              slidesPerView: 2,
              grid: {
                rows: 2,
                fill: 'row'
              },
              spaceBetween: 30
            },
            // when window width is >= 768px
            768: {
              slidesPerView: 3,
              grid: {
                rows: 2,
                fill: 'row'
              },
              spaceBetween: 30
            },
            // when window width is >= 1024px
            1024: {
              slidesPerView: 4,
              grid: {
                rows: 2,
                fill: 'row'
              },
              spaceBetween: 30
            }
          }}
          style={{ height: '700px', width: '100%' }}
          rowGap={1000}
        >
          <SwiperSlide>
            <div className="persona-card">
              <h3>Marketing Manager</h3>
              <p>A strategic professional focused on campaign optimization and ROI tracking</p>
              <ul>
                <li>Needs real-time analytics</li>
                <li>Values data-driven decisions</li>
                <li>Seeks competitive insights</li>
              </ul>
            </div>
          </SwiperSlide>

          <SwiperSlide>
            <div className="persona-card">
              <h3>Product Owner</h3>
              <p>An experienced leader balancing user needs with business goals</p>
              <ul>
                <li>Prioritizes feature development</li>
                <li>Focuses on user experience</li>
                <li>Manages stakeholder expectations</li>
              </ul>
            </div>
          </SwiperSlide>

          <SwiperSlide>
            <div className="persona-card">
              <h3>UX Researcher</h3>
              <p>A detail-oriented professional conducting user studies and analysis</p>
              <ul>
                <li>Conducts user interviews</li>
                <li>Analyzes behavioral data</li>
                <li>Creates user journey maps</li>
              </ul>
            </div>
          </SwiperSlide>

          <SwiperSlide>
            <div className="persona-card">
              <h3>Digital Strategist</h3>
              <p>A forward-thinking advisor shaping digital transformation</p>
              <ul>
                <li>Plans digital initiatives</li>
                <li>Evaluates emerging trends</li>
                <li>Develops growth strategies</li>
              </ul>
            </div>
          </SwiperSlide>

          <SwiperSlide>
            <div className="persona-card">
              <h3>Data Analyst</h3>
              <p>A detail-oriented professional who transforms raw data into actionable insights</p>
              <ul>
                <li>Creates data visualizations</li>
                <li>Identifies market trends</li>
                <li>Builds predictive models</li>
              </ul>
            </div>
          </SwiperSlide>

          <SwiperSlide>
            <div className="persona-card">
              <h3>Content Strategist</h3>
              <p>A creative professional who develops engaging content strategies</p>
              <ul>
                <li>Plans content calendars</li>
                <li>Optimizes for SEO</li>
                <li>Measures content performance</li>
              </ul>
            </div>
          </SwiperSlide>

          <SwiperSlide>
            <div className="persona-card">
              <h3>Brand Manager</h3>
              <p>A strategic thinker focused on building and maintaining brand identity</p>
              <ul>
                <li>Develops brand guidelines</li>
                <li>Manages brand perception</li>
                <li>Coordinates marketing efforts</li>
              </ul>
            </div>
          </SwiperSlide>

          <SwiperSlide>
            <div className="persona-card">
              <h3>Social Media Manager</h3>
              <p>A dynamic professional who manages online community engagement</p>
              <ul>
                <li>Creates social content</li>
                <li>Monitors engagement metrics</li>
                <li>Manages community feedback</li>
              </ul>
            </div>
          </SwiperSlide>
        </Swiper>
      </div>
    </div>
  );
}

export default OurPersonas;
